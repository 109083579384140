import React, { useRef, useEffect } from 'react'
import { useLocation, Switch } from 'react-router-dom'
import AppRoute from './utils/AppRoute'
import ScrollReveal from './utils/ScrollReveal'
import ReactGA from 'react-ga4'

// Layouts
import LayoutDefault from './layouts/LayoutDefault'

// Views
import Home from './views/Home'

// Google Analytics
const TRACKING_ID = 'G-CH2G7EVQ8B'
ReactGA.initialize(TRACKING_ID)

const App = () => {
  const childRef = useRef()
  let location = useLocation()

  useEffect(() => {
    document.body.classList.add('is-loaded')
    childRef.current.init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path='*' component={Home} layout={LayoutDefault} />
        </Switch>
      )}
    />
  )
}

export default App
