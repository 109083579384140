import ReactGA from 'react-ga4'

const useAnalyticsEventTracker = (category = 'unknown-category') => {
  const eventTracker = (action = 'click') => {
    ReactGA.event({ category, action })
  }
  return eventTracker
}

export default useAnalyticsEventTracker
