import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import Image from '../../elements/Image'

const Logo = ({ className, ...props }) => {
  const classes = classNames('brand', className)

  return (
    <div {...props} className={classes}>
      <h1 className='m-0'>
        <Link to='/'>
          <Image
            src={'https://i.imgur.com/7wQBwtJh.jpg'}
            alt='Open'
            width={40}
            height={40}
            style={{
              borderRadius: 100
            }}
          />
        </Link>
      </h1>
    </div>
  )
}

export default Logo
