import React from 'react'
import classNames from 'classnames'
import { SectionProps } from '../../utils/SectionProps'
import ButtonGroup from '../elements/ButtonGroup'
import Button from '../elements/Button'
import Image from '../elements/Image'
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker'

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const IN_STOCK = true

const PurchaseButton = () => {
  const gaEventTracker = useAnalyticsEventTracker('purchase')

  if (IN_STOCK) {
    return (
      <Button
        tag='a'
        color='primary'
        wideMobile
        href='https://whop.com/nefarious/?accessPassId=prod_lFtycTfUC57do&planId=plan_6c5KjE4zYzdLf'
        onClick={() => gaEventTracker('click')}
      >
        <p
          style={{
            color: 'black'
          }}
        >
          Purchase [$100/M]
        </p>
      </Button>
    )
  }

  return (
    <Button tag='a' color='dark' wideMobile href='https://whop.com/nefarious/'>
      Out of Stock
    </Button>
  )
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  )

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  )

  return (
    <section {...props} className={outerClasses}>
      <div className='container-sm'>
        <div className={innerClasses}>
          <div className='hero-content'>
            <h1
              className='mt-0 mb-16 reveal-from-bottom'
              data-reveal-delay='200'
            >
              <span className='text-color-primary'>Nefarious</span>
            </h1>
            <div className='container-xs'>
              <p
                className='m-0 mb-32 reveal-from-bottom'
                data-reveal-delay='400'
              >
                Premium trading group, by Enick ツ.
              </p>
              <div className='reveal-from-bottom' data-reveal-delay='600'>
                <ButtonGroup>
                  <PurchaseButton />
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div
            className='hero-figure reveal-from-bottom illustration-element-01'
            data-reveal-value='20px'
            data-reveal-delay='800'
          >
            <a href=''>
              <Image
                className='has-shadow'
                src='https://i.imgur.com/S2E2BHI.png'
                alt='Nefarious'
                width={896}
                height={504}
                style={{
                  borderRadius: 4
                }}
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

Hero.propTypes = propTypes
Hero.defaultProps = defaultProps

export default Hero
